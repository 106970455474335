import React from 'react'

const Habout = () => {
    return (
        <div className='grid md:grid-cols-2 grid-cols-1 my-10 lg:w-[80%] m-auto p-2' style={{ backgroundImage: "URL(image/habout/bg.webp)", backgroundSize: "100% 100%" }}>
            <div className=' w-full text-center'>
                <h1 className='text-[35px] font-bold aids '>We have faith in our ability to make a positive impact.</h1>
                <div className='w-[200px] h-[60px] m-auto my-2 flex justify-center items-center' style={{ backgroundImage: "URL(image/founder/Brush.webp)", backgroundSize: "100% 100%", backgroundRepeat: "no-repeat" }}>
                    <h1 className='text-[22px] text-white font-bold'>About Us</h1>
                </div>
                <p className='w-full text-justify font-thin text-[18px] tracking-wide my-2 p-3'>
                   Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore, quae provident? Itaque autem sequi accusamus, iste consectetur ad, commodi dolorum at debitis maxime enim quaerat? Iusto sunt esse harum unde.
                   Lorem ipsum, dolor sit amet consectetur adipisicing elit. Cum quas deleniti non similique dignissimos, possimus perferendis nobis ratione, reiciendis, autem harum accusamus. Doloremque dignissimos saepe ducimus repellat in voluptatum nihil!\
                   Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ut vel ea natus, delectus animi architecto nulla doloribus autem quas, eum consequatur? Sit praesentium illo corrupti deleniti explicabo. Mollitia, totam velit!
                </p>
                
            </div>

            <div className='relative sm:h-[380px] h-[300px] md:h-auto'>
                <div className='bg-blue-600 sm:w-[280px] w-[200px] h-[150px] z-0 absolute top-0 left-0'></div>
                <div>
                    <img src="img/g1.jpg" className='z-10 absolute p-4 w-full sm:h-[85%]  h-[95%] '  alt="" />
                </div>
                <div className='absolute bottom-0 right-0  sm:w-[70%] w-[90%] sm:p-4 p-2 text-white text-[12px] capitalize bg-blue-600 z-20'>
                    <p>Since its inception, HopeGivers Foundation has maintained a zero mortality rate. It is one of the few organizations recognized as a FIT institution. The Foundation reaches thousands of beneficiaries every year through its various services, including old age homes, blood donation drives, tree planting initiatives, and cloth distribution programs..</p>
                </div>
            </div>
        </div>
    )
}

export default Habout
