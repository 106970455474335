import React from 'react'
import EmblaCarousel from '../componate/EmblaCarousel'
import Founder from '../componate/Founder'
import Habout from '../componate/Habout'
import Whats from '../componate/Whats'
import Impact from '../componate/Impact'
import Contributions from '../componate/Contributions'
import Csr from '../componate/Csr'
import Media from '../componate/Media'
import Awards from '../componate/Awards'
import People from '../componate/People'
import Voice from '../componate/Voice'
import Donate from '../componate/Donate'
import Footer from '../componate/Footer'
import Awardsnational from '../componate/Awardsnational'


function HomePage() {
  return (
    <div>
      <EmblaCarousel/>
      <Founder/>
      <Habout/>
      <Whats/>
      <Impact/>
      <Contributions/>
      <Awardsnational/>
      <Awards/>
      <People/>
      
    </div>
  )
}

export default HomePage
