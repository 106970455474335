import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FaPlayCircle } from "react-icons/fa";

const People = () => {
    const imageUrls = [
        {
            img: "image/people/1.png",
            h1: "Alfred Schneider",
            h2: "Chairman, SFS Group India",
            p: " “We are happy to associate with Hopegivers Foundation. I am happy to see their activities and appreciate their efforts.”",
        },
        {
            img: "image/people/1.png",
            h1: "Ramesh Kumar",
            h2: "CEO, Tech Solutions",
            p: " “The work Hopegivers Foundation is doing is truly inspiring and impactful. We are proud to support their mission.”",
        },
        {
            img: "image/people/1.png",
            h1: "Sneha Mehta",
            h2: "Managing Director, Mehta Industries",
            p: " “Hopegivers Foundation's dedication to improving lives is commendable. We are honored to partner with them.”",
        },
        {
            img: "image/people/1.png",
            h1: "Vikram Patel",
            h2: "Founder, Patel Enterprises",
            p: " “We admire the relentless efforts of Hopegivers Foundation in uplifting communities. Keep up the great work!”",
        },
        {
            img: "image/people/1.png",
            h1: "Anita Roy",
            h2: "Director, Roy Holdings",
            p: " “Supporting Hopegivers Foundation has been a rewarding experience for us. They are making a real difference.”",
        },
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToScroll: 1, // Number of slides to scroll
        autoplay: true,
    };

    const smallSettings = {
        ...settings,
        slidesToShow: 1, // Number of slides to show at once on small screens
    };

    const mediumSettings = {
        ...settings,
        slidesToShow: 3, // Number of slides to show at once on medium screens
    };

    return (
        <div className='w-full py-5 bg-[#ffffff]'>
            <div className='w-full my-3'>
                <div className='w-[280px] md:w-[320px] m-auto h-[80px] flex justify-center items-center' style={{ backgroundImage: "URL(image/founder/Brush.webp)", backgroundSize: "100% 100%", backgroundRepeat: "no-repeat" }}>
                    <h1 className='sm:text-[22px] text-[20px] font-bold text-white'>What People Say About Us</h1>
                </div>
            </div>
            <Slider className='bg-[#ffffff] slidescrolls slider-images' {...(window.innerWidth < 850 ? smallSettings : mediumSettings)}>
                {imageUrls.map((imageUrl, index) => (
                    <div key={index} className='p-3'>
                        <div className='slidescrolls w-[80%] p-2' style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
                            <div className='w-full p-4 rounded-md relative flex flex-col items-center'>
                                <div className='w-[90%] h-[100px] bg-blue-600 absolute top-1 z-0'></div>
                                <div className='w-full flex justify-center items-center z-10'>
                                    <img src={imageUrl.img} className='w-[50%]' alt={imageUrl.h1} />
                                </div>
                                <div className='w-full p-3 text-center'>
                                    <h2 className='font-bold p-2'>{imageUrl.h1}</h2>
                                    <h4>{imageUrl.h2}</h4>
                                </div>
                                <p className='md:px-4 px-6 text-center py-2'>{imageUrl.p}</p>
                                <div className='w-full p-4 flex justify-end'>
                                    <FaPlayCircle className='text-[42px] text-blue-400 cursor-pointer' />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default People;
