import React from 'react';
import ContactsCCom from '../componate/ContactsCCom'
import Contectus from '../componate/Contectus'

export default function ContactPage() {
  return (
    <div className='py-20'>
        <Contectus/>
    <ContactsCCom/>
    </div>
  )
}
