import React from 'react'
import { FaHome,FaSchool ,FaPencilRuler,FaHospital ,FaUsers ,FaUserFriends   } from "react-icons/fa";

import CountUp from 'react-countup';
const Impact = () => {
  const data = [
    {
      img: <FaHome />,
      p: "Care Home",
      no: 4200
    },
    {
      img: <FaSchool  />,
      p: "Utkarsha Learning Center",
      no: 3000
    }
    ,
    {
      img: <FaPencilRuler  />,
      p: "Aadhar Vidya Program",
      no: 38000
    },
    {
      img: <FaHospital  />,
      p: "Gokak Care and Support Center",
      no: 81000
    },
    {
      img: <FaUsers   />,
      p: "Community Support",
      no: 5400
    },
    {
      img: <FaUserFriends   />,
      p: "Social Responsibilities of Youth",
      no: 900000
    }
  ]
  return (
    <div className='w-full  my-5 flex justify-center items-center' style={{ backgroundImage: "URL(image/impact/bg.webp)", backgroundSize: "auto 100%", backgroundRepeat: "no-repeat" }}>
      <div className='w-full my-6'>
        <div className='w-[200px] m-auto h-[50px] flex justify-center items-center' style={{ backgroundImage: "URL(image/founder/Brush.webp)", backgroundSize: "100% 100%", backgroundRepeat: "no-repeat" }}>
          <h1 className='text-[20px] font-bold text-white '> Our Impact</h1>
        </div>
        <p className='text-center my-4 text-white font-semibold text-[22px]'>
          Leading a lasting change across multiple avenues
        </p>
        <div className='w-[80%] m-auto grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-9 py-4'>
          {
            data.map(
              (d, i) => {
                return (
                  <div key={i} className='text-white flex justify-center items-center flex-col hover:scale-110 duration-700 my-5'>
                    <span className='text-[70px]' >{d.img}</span>
                    <p className='text-[20px] font-sans p-3'>{d.p}</p>
                    <h1 className='text-[30px] font-bold'><CountUp end={d.no} />+</h1>
                  </div>
                )
              }
            )
          }

        </div>
      </div>
    </div >
  )
}

export default Impact
