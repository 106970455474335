import React from 'react'

const Founder = () => {
    return (
        <div className='w-full h-auto relative overflow-hidden my-4' >
            <div className='w-full pt-5 relative'>
                <div className='w-[250px] m-auto h-[80px] flex justify-center items-center' style={{ backgroundImage: "URL(img/5.jpg)", backgroundSize: "100% 100%", backgroundRepeat: "no-repeat" }}>
                    <h1 className='text-[22px] font-bold text-white '> Founder's Story!</h1>
                </div>
                <div className='w-full grid lg:grid-cols-2 grid-cols-1' style={{ backgroundImage: "URL(image/founder/bg.webp)", backgroundSize: "100% 100%" }}>
                    <div className='lg:absolute top-0  lg:w-[50%] flex justify-center'>
                        <div className=' mt-8 lg:w-[60%] w-[100%] flex justify-center items-center '>
                            <div className='border-4 border-blue-400 sm:w-[400px] sm:h-[380px] w-[300px] h-[280px]  px-2 rounded-full '>
                                <div className='border-4 border-[#5f625f] rounded-full w-full h-full flex justify-center 
                            ' >
                                    <img src="img/6.jpg" className='w-full h-full  rounded-full ' alt="" />    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='lg:block hidden'>

                    </div>

                    <div className='w-full lg:my-[100px] '>

                        <div className='lg:w-full w-[80%] m-auto flex-col  flex justify-center mt-4  p-2 ' >
                            <p className='text-[18px] P-2 font-sans'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio pariatur itaque accusantium sequi quam esse? Iste ad ex unde velit eligendi tempore neque perferendis fuga. Excepturi assumenda soluta tempore fuga!</p>

                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default Founder
