import React from 'react'

const Whats = () => {
    const data = [
        {
            img: "image/whats/11.webp",
            img1: "image/whats/1.webp",
            h1: "Education",
            p: " Care Home Safe, clean and caring home for medically challenged children to live life with dignity. children are provided healthcare, nutrition and education"
        },
        {
            img: "image/whats/22.webp",
            img1: "image/whats/2.webp",
            h1: "Health Care",
            p: "A safe, clean, and caring home for elderly individuals to live life with dignity. Residents are provided with healthcare, nutrition, and education to ensure their well-being and quality of life"
        },
        {
            img: "image/whats/44.webp",
            img1: "image/whats/4.webp",
            h1: "Ashirwad Old Age Home",
            p: "A safe, clean, and caring home for elderly individuals to live life with dignity. Residents are provided with healthcare, nutrition, and education to ensure their well-being and quality of life."
        },{
            img: "image/whats/44.webp",
            img1: "image/whats/4.webp",
            h1: "tree planting initiatives",
            p: " Dedicated to environmental sustainability,environmental sustainability through its active tree planting initiatives, contributing to a greener and healthier planet.."
        },{
            img: "image/whats/44.webp",
            img1: "image/whats/4.webp",
            h1: "cloth distribution",
            p: " we also focus on cloth distribution to support those in need. Our cloth distribution program aims to provide essential clothing to underprivileged communities, ensuring that everyone has access to basic necessities while promoting environmental responsibility through recycling and reusing garments."
        },{
            img: "image/whats/44.webp",
            img1: "image/whats/4.webp",
            h1: "free health check-up ",
            p: "dedicated to community well-being through its free health check-up initiatives. These services aim to ensure the health and wellness of the elderly, offering comprehensive medical evaluations and promoting preventive healthcare practices."
        }
    ]
    return (
        <div className='w-full '>
            <div className='w-[80%] m-auto grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 md:gap-3 gap-5 my-8 ' >
                {
                    data.map(
                        (d, i) => {
                            return (
                                <div key={i} className='w-full group mt-5 hover:mt-[50px] duration-700 '>
                                    <div className='w-[100%] m-auto border-t-2 border-blue-600 pb-4  mt-2' style={{ boxShadow: " rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}>
                                        <div className='w-full flex  justify-center gap-3 relative '>
                                            <div className=' w-[80px] h-[75px] flex justify-center items-center p-2 rounded-full absolute top-[-40px] left-1 bg-white' style={{ boxShadow: " rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}>
                                                <img src={d.img} className='w-[45px]  group-hover:hidden' alt="" />
                                                <img src={d.img1} className='w-[45px] group-hover:block hidden' alt="" />
                                            </div>
                                            <span className='font-semibold capitalize pl-6'>{d.h1}</span>
                                        </div>
                                        <p className='p-4 py-8 aids '>

                                            {d.p}
                                        </p>
                                        <div className='w-full flex justify-center items-center mb-8'>
                                            <button className='border-2 px-2 py-1 m-auto bg-[#9a9696] rounded-md'>Contact</button>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    )
                }
            </div>
        </div>
    )
}

export default Whats
