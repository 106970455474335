import React from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Carousel } from "flowbite-react";
const Media = () => {
    const imageUrls = [
        'image/media/loka.webp',
        'image/media/loka.webp',
        'image/media/loka.webp',
        'image/media/loka.webp',
        'image/media/loka.webp',
        'image/media/loka.webp',
       
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToScroll: 1 // Number of slides to scroll
    };

    const smallSettings = {
        ...settings,
        slidesToShow: 2 // Number of slides to show at once on small screens
    };

    const mediumSettings = {
        ...settings,
        slidesToShow: 3 // Number of slides to show at once on medium screens
    };

    
    return (
        <div  className='w-full  py-5 bg-[#f9ebeb] '>
            <div  className='w-full my-3'>
            <div className='w-[250px] m-auto h-[80px] flex justify-center items-center' style={{ backgroundImage: "URL(image/founder/Brush.webp)", backgroundSize: "100% 100%", backgroundRepeat: "no-repeat" }}>
                    <h1 className='text-[22px] font-bold text-white '> Media Coverage</h1>
                </div>
            </div>
            <Slider  autoplay infinite speed={1000} className='bg-[#f9ebeb] slidescrolls slider-images' {...(window.innerWidth < 768 ? smallSettings : mediumSettings)}>
                {imageUrls.map((imageUrl, index) => (
                    <div key={index} className='slidescrolls   p-2 '>
                        <img src={imageUrl} className='p-4 m-auto' alt={`Image ${index}`} />
                    </div>
                ))}
            </Slider>

            
        </div>
    )
}

export default Media
