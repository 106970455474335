import React from 'react'
import HomePage from './Page/HomePage'
import AboutPage from './Page/AboutPage'
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Main from './Page/Main';
import ContactPage from './Page/ContactPage';


const router = createBrowserRouter([
  {
    path: '/',
    element: <Main />,
    children: [
      {
        path: '/',
        element: <HomePage />
      },
      
      {
        path: 'about',
        element: <AboutPage />
      },
      {
        path: 'contact',
        element: <ContactPage />
      }
    ]
  }
]);


const App = () => {
  return (
    <RouterProvider router={router} />
  )
}

export default App
