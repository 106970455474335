import React from 'react'
import { CiLocationOn } from "react-icons/ci";
import { IoCallOutline } from "react-icons/io5";
import { CiMail } from "react-icons/ci";
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";



const Footer = () => {
    const footnav = [
        {
            h: "About Us"
        },
        {
            h: "Contact Us"
        },
        {
            h: "FAQs"
        },
        {
            h: "Policies"
        },
        {
            h: "CPP"
        },
        
    ]
    return (
        <div className='w-full bg-[#1a1aba]'>
            <div className='sm:w-[80%] m-auto p-4'>
                <div className='w-full p-4'>
                    <img src="img/footer-logo.png" className='w-[250px] h-[80px]' alt="" />
                </div>
                <div className=' items-center grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-2'>
                    <div className='flex lg:border-none border-b border-white pb-2 items-center gap-2 text-white my-2'>
                        <CiLocationOn className='text-[22px]' /><a href=""> 164/113, Main Haldi Ghati Marg

Pratap Nagar, Jaipur</a>
                    </div>
                    <div className='flex items-center lg:border-none border-b border-white pb-2 gap-2 text-white my-2'>
                        <IoCallOutline className='text-[22px]' /> <a href="">+91  9672314848</a>
                    </div>
                    <div className='flex items-center lg:border-none border-b border-white pb-2 gap-2 text-white my-2'>
                        <CiMail className='text-[22px]' /> <a href="">hopegiversfoundation99@gmail.com</a>
                    </div>
                    <div className='flex gap-3  md:justify-end text-[28px] text-white'>
                        <FaFacebook />
                        < FaInstagram />
                        <FaLinkedin />
                        <FaYoutube />
                        <FaXTwitter />
                    </div>
                </div>
                <hr className='lg:block hidden' />
                <div className='w-full grid md:grid-cols-2 grid-cols-1 my-3'>
                    <div className='p-2 text-white md:order-1  order-2'>
                        Copyright 2024 @ hopegiversfoundation All Rights Reserved.
                    </div>
                    <ul className='flex md:order-2 order-1 flex-wrap lg:border-none border-b border-white  justify-center p-2 gap-3 text-white'>
                       {
                        footnav.map(
                            (d,i)=>{
                                return(
                                    <li key={i}>{d.h}</li>
                                )
                            }
                        )
                       }

                    </ul>

                </div>
            </div>
        </div>
    )
}

export default Footer
