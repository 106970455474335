
import React from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import Autoplay from 'embla-carousel-autoplay'

export default function EmblaCarousel() {
    const [emblaRef] = useEmblaCarousel({ loop: true }, [Autoplay({ delay: 3000 })])

    return (
        <div className="embla  w-full mb-20 " ref={emblaRef}>
            <div className="embla__container h-[400px]  lg:h-[600px] ">
                <div className="embla__slide">
                    <img src="img/c1.jpg" alt="" className='setslider' />
                </div>
                <div className="embla__slide">
                    <img src="img/c2.jpg" alt="" className='setslider' />
                </div>
                <div className="embla__slide">
                    <img src="img/c3.jpg" alt="" className='setslider' />
                </div> 
                <div className="embla__slide">
                    <img src="img/a1.jpg" alt="" className='setslider' />
                </div>
            </div>
        </div>
    )
}

