import React from 'react'
import { MdMarkEmailUnread } from "react-icons/md";
import { FaMobileAlt } from "react-icons/fa";
import { LuPin } from "react-icons/lu";


<iframe src="" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

export default function Contectus() {
    const items = [
        {
            icon: <LuPin />,
            name: "Head Office",
            a: " 164/113, Main Haldi Ghati Marg Pratap Nagar, Jaipur",
            url: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d113856.45410657689!2d75.76944566914416!3d26.90304473172526!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396dc9a01250be6d%3A0xdfd25fcd1205129!2sHaldi%20Ghati%20Road%2C%20Pratap%20Nagar!5e0!3m2!1sen!2sin!4v1721371266376!5m2!1sen!2sin"
        },

        {
            icon: <FaMobileAlt />,
            name: "Email",
            a: "HopeGiversfoundation99@gmail.com",
            url: "HopeGiversfoundation99@gmail.com"
        },

        {
            icon: <MdMarkEmailUnread />,
            name: "Phone",
            a: "Landline: +91-9602034848 / +91-9672314848",
            url: "tel:+919672314848"
        },
    ]
    return (
        <div className='max-w-[1200px] mx-auto'>

            <div className='grid my-4 md:grid-cols-3  grid-cols-1  lg:gap-6 gap-10 px-8 py-8'>
                {
                    items.map(
                        (d, i) => {
                            return (
                                <div className='w-full   border-separate p-2 '>
                                    <div className='flex items-center justify-center text-[40px] text-blue-950'>{d.icon}
                                    </div>
                                    <div className='flex items-center justify-center text-[20px] md:text-[25px] text-[#090909] font-medium pl-[15px] pt-[20px]'><h1>{d.name}</h1></div>

                                    <div className='flex items-center text-center justify-center text-[16px] text-[#45454c]  pl-[15px] pt-[20px]'>
                                        <a href={d.url}>{d.a}</a>
                                    </div>
                                </div>
                            )
                        }
                    )
                }
            </div>
        </div>
    )
}


