import React from 'react'

const Contributions = () => {
    return (
        <div className='w-full my-5 py-3' style={{backgroundImage:"URL(image/contribution/bg.webp)",backgroundSize:"100% 100%"}} >
            <div className='md:w-[480px] text-center m-auto h-[80px] flex justify-center items-center my-3' style={{ backgroundImage: "URL(image/founder/Brush.webp)", backgroundSize: "100% 100%", backgroundRepeat: "no-repeat" }}>
                <h1 className='text-[20px] font-bold font-serif text-white '> How are your contributions utilized?</h1>
            </div>
            <div className='w-[80%] m-auto grid md:grid-cols-2 grid-cols-1 gap-4'>
                <div className='w-full relative hover:rotate-[15deg] duration-700'>
                    <div className='absolute top-[55%] z-30 left-[40%] text-white'>90%</div>
                    <div className='absolute top-[20%] z-30 right-[20%] text-white'>8%</div>
                    <div className='absolute top-[35%] z-30 right-[10%] text-white'>2%</div>
                    <img src="image/contribution/Blue.webp" className='rotate-90 z-0 w-[89%]' alt="" />
                    <img src="image/contribution/Green .webp" className='rotate-[68deg] absolute w-[23%] z-20 top-[-1%] right-[24%]' alt="" />
                    <img src="image/contribution/Orange.webp" className='rotate-[118deg] absolute w-[40%] top-[16%] right-[5%]' alt="" />
                </div>
                <div className='w-full flex flex-col gap-5 items-start pl-4 justify-center'>
                    <div className='flex gap-3'>
                        <div className='w-[50px] h-[25px] bg-blue-600'></div><p className='font-serif font-bold'>
                        Direct Program Expenses</p>
                    </div>
                    <div className='flex gap-3'>
                        <div className='w-[50px] h-[25px] bg-green-600'></div><p className='font-serif font-bold'>
                        Admin Expenses</p>
                    </div>
                    <div className='flex gap-3'>
                        <div className='w-[50px] h-[25px] bg-[#ec982b]'></div><p className='font-serif font-bold'>
                        Fundraising Expenses</p>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default Contributions;
