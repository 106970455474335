import React from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Carousel } from "flowbite-react";
const Awardsnational = () => {
   

    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToScroll: 1 // Number of slides to scroll
    };

    const smallSettings = {
        ...settings,
        slidesToShow: 2 // Number of slides to show at once on small screens
    };

    const mediumSettings = {
        ...settings,
        slidesToShow: 3 // Number of slides to show at once on medium screens
    };

    const data = [
       
        {
            img: "URL(img/a1.jpg)",
            
         },
         {
            img: "URL(img/a2.jpg)",
            
             
         },
         {
            img: "URL(img/a3.jpg)",
          
         },
         {
            img: "URL(img/a1.jpg)",
            
    
         },
    ]
    return (
        <div  className='w-full  py-5 bg-[#f9ebeb] '>
            <div className="h-[30vh] md:h-[60vh] lg:h-screen">
                <Carousel>
                    {
                        data.map(
                            (d, i) => {
                                return (
                                    <div key={i} className='w-full h-full flex justify-end items-center' style={{ backgroundImage:`${d.img}` , backgroundRepeat: "no-repeat", backgroundSize: "100% 100%" }}>
                                        {/* <div className=' w-full flex justify-center items-center'>
                                            <p className='w-[30%] m-auto aids text-white md:text-[40px] sm:text-[22px] text-[20px] text-center font-bold'>{d.h}</p>
                                        </div> */}
                                    </div>
                                )
                            }
                        )
                    }
                    

                </Carousel>
            </div>
        </div>
    )
}



export default Awardsnational
