import React from 'react'

const AboutMain = () => {
  return (
    <div className='w-full md:h-[600px] h-[500px] flex justify-end items-center ' style={{backgroundImage:"URL(img/2.jpg)" ,backgroundSize:"100% 100%"}}>
      <div className='md:text-[42px] text-[25px] pr-[30%]  text-white font-bold'> Abouts Us</div>
    </div>
  )
}

export default AboutMain
