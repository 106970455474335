import React from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Carousel } from "flowbite-react";
const Awards = () => {
 
    const imageUrls = [
        {
            img: 'img/a1.jpg',
            h1: "Young Achievement Award in 2017"
        },
        {
            img: 'img/a2.jpg',
            h1: "Young Achievement Award in 2017"
        },

        {
            img: 'img/a3.jpg',
            h1: "Young Achievement Award in 2017"
        },

        {
            img: 'img/a1.jpg',
            h1: "Young Achievement Award in 2017"
        },

        {
            img: 'img/a3.jpg',
            h1: "Young Achievement Award in 2017"
        },





    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToScroll: 1 // Number of slides to scroll
    };

    const smallSettings = {
        ...settings,
        slidesToShow: 1 // Number of slides to show at once on small screens
    };

    const mediumSettings = {
        ...settings,
        slidesToShow: 3 // Number of slides to show at once on medium screens
    };


    return (
        <div className='w-full  py-5 bg-[#ffffff] '>
            <div className='w-full my-3'>
                <div className='w-[280px] m-auto h-[80px] flex justify-center items-center' style={{ backgroundImage: "URL(image/founder/Brush.webp)", backgroundSize: "100% 100%", backgroundRepeat: "no-repeat" }}>
                    <h1 className='text-[22px] font-bold text-white '> Awards & Recognitions</h1>
                </div>
            </div>
            <Slider autoplay infinite speed={1000} className='bg-[#ffffff] slidescrolls slider-images' {...(window.innerWidth < 850 ? smallSettings : mediumSettings)}>
                {imageUrls.map((imageUrl, index) => (
                    <div key={index}>
                        <div  className='slidescrolls   p-2 '>
                            <div className='w-[280px] h-[280px] flex justify-center items-center ' style={{ backgroundImage: "URL(image/award/Circle.webp)", backgroundSize: "100% 100%" }}>
                                <img src={imageUrl.img} className='p-2  rounded-full' alt={`Image ${index}`} />
                            </div>
                           
                        </div>
                        <p className='p-2 text-center'>{imageUrl.h1}</p>

                    </div>
                ))}
            </Slider>

        </div>
    )
}

export default Awards
