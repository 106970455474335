import React from 'react'
import AboutMain from '../componate/AboutMain'
import Mahesh from '../componate/Mahesh'
import Awards from '../componate/Awards'
import Donate from '../componate/Donate'

const AboutPage = () => {
  return (
    <div>
      <AboutMain />
      <Mahesh />
      <Awards />
    </div>
  )
}

export default AboutPage
