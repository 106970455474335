import React from 'react'

const Mahesh = () => {
    return (
        <div className='w-full  m-auto grid lg:grid-cols-2 gap-3 grid-cols-1 my-5 py-6 px-4'>
            <div className='my-4'>
                <div className='w-full my-3'>
                    <div className='w-[280px] sm:w-[350px] m-auto h-[80px] flex justify-center items-center' style={{ backgroundImage: "URL(image/founder/Brush.webp)", backgroundSize: "100% 100%", backgroundRepeat: "no-repeat" }}>
                        <h1 className='sm:text-[25px] text-[20px] font-bold aids text-white '>About  hopegiversfoundation</h1>
                    </div>
                </div>
                <div className='text-center my-3'>
                    <p className='text-[22px] aids py-2'>We Believe we can make a difference</p>
                </div>
                <div className='text-justify aids my-3'>
                    <p className='px-4  py-2'>ope Givers Foundation   Started her  Ashirvad  old age Home  persons in Pratap Nagar Jaipur(Raj.)  safe accommodation, tender love, proper care, nourishment, health service and other basic needs through establishment and Considering after the humanity for old age people whose welfare and maintenance are not being looked after by their kid and this especially their sons, daughters, daughters-in-law and sons-in-lawothers upon whom the burden of maintenance lies. Our Organization has come forward to get Such persons .
                        whose lives become pathetic and miserable to admit them into thís old age home without expecting any financial or any sort of help from them or from their people and the main object and motto is look after their welfare such as food, shelter ,clothing.
                        Ashirvad  Old Age Home take care undertake to attend to the treatment minor ailments such as cough, fever ,headache, Per Operation & Post Operation Care under doctor consult for old age people and the like any major ailments their by permission This old age home is rendering the services whole heartedly and without any profit motive and exclusively service oriented their nearest persons.
                    </p>
                </div>
            </div>
            <div className='w-full flex justify-center items-center my-6'>
                <div className='sm:w-[500px] sm:h-[450px] w-[280px] h-[250px] relative flex justify-center items-center' style={{ backgroundImage: "URL(image/about/maheshfound/cricle.webp)", backgroundSize: "100% 100%" }}>
                    <div className='absolute top-0 left-0 w-[30%] rounded-full'>
                        <img src="img/c4.jpg" className='w-full  rounded-full' alt="" />
                        <p className='text-center capitalize font-semibold'>Care Home</p>
                    </div>
                    <div className='absolute right-0 top-0 w-[30%] rounded-full'>
                        <img src="img/c4.jpg" className='w-full  rounded-full' alt="" />
                        <p className='text-center capitalize font-semibold'>Education</p>
                    </div>
                    <div className='absolute left-0 bottom-0 w-[30%] rounded-full'>
                        <img src="img/c4.jpg" className='w-full  rounded-full' alt="" />
                        <p className='text-center  capitalize font-semibold'>Community</p>
                    </div>
                    <div className='absolute right-0 bottom-0 w-[30%] rounded-full'>
                        <img src="img/c4.jpg" className='w-full  rounded-full' alt="" />
                        <p className='text-center capitalize font-semibold'>Women Empowerment</p>
                    </div>
                    <div>
                        <img src="img/c2.jpg" className='w-[50%] m-auto  ' alt="" />
                    </div>

                </div>

            </div>
        </div>
    )
}

export default Mahesh
