import React from 'react'
import { Outlet } from 'react-router-dom'
import Footer from '../componate/Footer'
import Nav from '../componate/Nav'

export default function Main() {
    return (
        <>
            <Nav />
            <Outlet />
            <Footer />
        </>
    )
}
